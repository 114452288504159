import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonHttpService } from '@app/core/services/common-http.service';
import { Observable } from 'rxjs';
import { filterModel } from '../models/filter.model';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})


@Injectable()
export class FilterService {
  
  constructor( private commonHttpService: CommonHttpService,) { }

  public completedLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}loads/completed`,params)
  }
  public recentLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}dashboard/recent-loads`,params)
  }
  public disputeLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}loads/dispute`,params)
  }
  public activeLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}load/active`,params)
  }
  public openLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}load/open`,params)
  }
  public upcomingLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}upcoming-loads`,params)
  }
  public sentOfferLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}load/sent-offers`,params)
  }
  public offerLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}load/offers`,params)
  }
  public applyBidLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}load/applied-bids`,params)
  }
  public invitedLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}load/invitations`,params)
  }
  public rfpLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}rfps`,params)
  }
  public rfpContracts(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}rfp-load-contracts`,params)
  }
  public rfpSentOffers(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}rfp-load-offers`,params)
  }
  public rfpOffers(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}rfps/load/offers`,params)
  }
  public rfpAppliedBid(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}rfps/load/applied`,params)
  }
  public driverActiveLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}active-loads`,params)
  }
  public driverUpcomingLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}upcoming-loads`,params)
  }
  public driverCompletedLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}completed-loads`,params)
  }
  public superAdminShipper(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}`,params)
  }

  public superAdminLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}`,params)
  }

  public allCarriersShippers(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}`,params)
  }
  public coveredLoads(filterModel:filterModel,endPoint:string): Observable<ResponseModel> {
    let params = new HttpParams();
    for (var key in filterModel) {
      if (filterModel.hasOwnProperty(key)) {
        params = params.append(key ,filterModel[key]);
      }
    }
    return this.commonHttpService.get<ResponseModel>(`${endPoint}load/covered`,params)
  }
}

 
