import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  onDataRecieved = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    private _domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data.description = this._domSanitizer.bypassSecurityTrustHtml(this.data.description);
  }

  ngOnInit(){
    
  }

  onClickYes(): void {
    this.dialogRef.close();
    this.onDataRecieved.emit(true);
  }

  onClickNo(): void {
    this.dialogRef.close();
    this.onDataRecieved.emit(false);
  }

  closeDialog():void{
    this.dialogRef.close();
  }

}
