/**
 * This is the theme module of the application, It contain the application theme related dependencies
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import MaterialComponentsModule from '@theme/material-components.module';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    MaterialComponentsModule,
    ReactiveFormsModule
  ],
  exports: [SharedModule, MaterialComponentsModule, ReactiveFormsModule],
})
export class AppThemeModule { }
