import { Injectable } from '@angular/core';
import { CommonHttpService } from '@app/core/services/common-http.service';
import { CommonService } from '@app/core/services/common.service';
import { Observable } from 'rxjs';
import { PaginationModel } from '@app/models/query-models';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoadService {
  private route = 'solodriver/load/'  

  constructor(
    private commonHttpService: CommonHttpService,
  ) { }
  

  public getLoads(argPaginationModel: PaginationModel = null):Observable<any>{
    let  params = new HttpParams();
    params= params.append('pageNumber', `${argPaginationModel.pageNumber+1}`).set('pageSize', `${argPaginationModel.recordCount}`).set('sortBy', `${argPaginationModel.sortBy}`).set('orderBy', `${argPaginationModel.orderBy}`);
    return this.commonHttpService.get<any>(`${this.route}open`,params)
   }

  public getLoad(argLoadId:any):Observable<any>{
    return this.commonHttpService.get<any>(`${this.route}open/detail?loadId=${argLoadId}`)
  }

  public applyBid(argBidModel:any):Observable<any>{
    return this.commonHttpService.post<any, any>(`${this.route}apply`, argBidModel)
  }

  public getHired(argModel:any):Observable<any>{
    const hired = 'get-hired';
    return this.commonHttpService.post<any, any>(`${this.route}${hired}`, argModel);
  }

  public assignRfpDriver(argModel:any):Observable<any>{
    const hired = 'update-truck-driver-trailer';
    return this.commonHttpService.post<any, any>(`${this.route}${hired}`, argModel);
  }

  public getActiveLoads(argPaginationModel: PaginationModel = null):Observable<any>{
    const activeLoads = 'active-loads'
    let params = new HttpParams();
    params = params.append('pageNumber', `${argPaginationModel.pageNumber+1}`).set('pageSize', `${argPaginationModel.recordCount}`);
    return this.commonHttpService.get<any>(`${'solodriver/'}${activeLoads}`,params)
  }

  public getActiveLoad(argLoadId: string): Observable<any> {
    const activeLoad  = 'active/detail'
    return this.commonHttpService.get<any>(`${this.route}${activeLoad}?loadId=${argLoadId}`)

  }

  public getAppliedBidsList(argPaginationModel: PaginationModel = null):Observable<any>{
    const appliedBids = 'applied-bids'
    let params = new HttpParams();
    params = params.append('pageNumber', `${argPaginationModel.pageNumber+1}`).set('pageSize', `${argPaginationModel.recordCount}`).set('sortBy', `${argPaginationModel.sortBy}`).set('orderBy', `${argPaginationModel.orderBy}`);
    return this.commonHttpService.get<any>(`${this.route}${appliedBids}`,params)
  }

  public getInvitations(argPaginationModel: PaginationModel = null):Observable<any>{
    const invitations = 'invitations'
    let params = new HttpParams();
    params = params.append('pageNumber', `${argPaginationModel.pageNumber+1}`).set('pageSize', `${argPaginationModel.recordCount}`).set('sortBy', `${argPaginationModel.sortBy}`).set('orderBy', `${argPaginationModel.orderBy}`);
    return this.commonHttpService.get<any>(`${this.route}${invitations}`,params)
  }

  public editBid(argBidModel:any): Observable<any> {
    const editBid = 'edit-bid'
    return this.commonHttpService.put(`${this.route}${editBid}` , argBidModel);
  }

  public withdrawBid(argBid:any): Observable<any> {
    const withdrawBid = 'withdraw-bid'
    return this.commonHttpService.put(`${this.route}${withdrawBid}/${argBid.bidId}` , argBid);
  }

  public acceptDeclineInvitation(argInvitationAcceptDecline:any):Observable<any>{
    const acceptOrDecline = 'invitations/accept-or-decline'
    return this.commonHttpService.post<any, any>(`${this.route}${acceptOrDecline}`, argInvitationAcceptDecline)
  }

}
