import { Injectable } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { CommonHttpService } from '@app/core/services/common-http.service';
import { Observable } from 'rxjs';
import { ShipmentLoad } from '../models/create-load/create-load';
import { PaginationModel } from '@app/models/query-models';
import { HttpParams } from '@angular/common/http';
import { BidAcceptDecline, WithdrawOfferModel } from '../components/shipments/load/open-loads-detail/open-loads-detail.component';
import { AssignContractModel } from '../components/shipments/active loads/assign-contract/assign-contract.component';
import { MarkFavouriteCarrierModel } from '@app/shared/components/carrier-profile/carrier-profile.component';
import APIResponseModel from '@app/core/models/api-response-model';
import { ResponseModel } from './../../../shared/models/response-model'
@Injectable({
  providedIn: 'root'
})
export class LoadService {
  private route = `shipper`;
  constructor(
    private commonHttpService: CommonHttpService,
    private commonService: CommonService
  ) { }


  // Get favorite carriers
  public favouriteCarriers(argQueryString: string): Observable<ResponseModel> {
    return this.commonHttpService.get<any>(`${this.route}/favorite-carriers?searchKeyword=${argQueryString}`);
  }

  public allFavouriteCarriers(argPaginationModel: PaginationModel = null, carrType = 1): Observable<ResponseModel> {
    let params = new HttpParams();
    params = params.append('pageNumber', `${argPaginationModel.pageNumber + 1}`).set('pageSize', `${argPaginationModel.recordCount}`).set('searchKeyword', `${argPaginationModel.searchKeyword}`);
    if (carrType == 1) {
      return this.commonHttpService.get<any>(`${this.route}/carriers/F?${params}`);
    } else {
      return this.commonHttpService.get<any>(`${this.route}/carriers/A?${params}`);
    }

  }
  // Assign contract
  public assignContract(argAssignContractModel: AssignContractModel): Observable<any> {
    return this.commonHttpService.post<AssignContractModel, AssignContractModel>(`${this.route}/load/assigned-contract`,
      argAssignContractModel);
  }


  // Add load
  public addLoad(argLoadModel: ShipmentLoad.CreateLoadModel): Observable<any> {
    return this.commonHttpService.post<ShipmentLoad.CreateLoadModel, ShipmentLoad.CreateLoadModel>(`${this.route}/load`, argLoadModel);
  }

  // Get open loads
  public getLoadList(argPaginationModel: PaginationModel = null): Observable<any> {
    let params:any = new HttpParams();
    params = params.append('pageNumber', `${argPaginationModel.pageNumber + 1}`).set('pageSize', `${argPaginationModel.recordCount}`).set('sortBy', `${argPaginationModel.sortBy}`).set('orderBy', `${argPaginationModel.orderBy}`);
    return this.commonHttpService.get<any>(`${this.route}/load/open`, params);
  }


  // Delete Load
  public deleteLoad(argLoadId: string): Observable<any> {
    return this.commonHttpService.delete(`${this.route}/load/${argLoadId}`);
  }


  // Update load
  public editLoad(argLoad: ShipmentLoad.CreateLoadModel): Observable<any> {
    return this.commonHttpService.put(`${this.route}/load/${argLoad.id}`, argLoad);
  }


  // Get open load detail
  public getLoad(argLoadId: string): Observable<any> {
    return this.commonHttpService.get<any>(`${this.route}/load?id=${argLoadId}`);

  }


  // Accept or Decline Bid
  public bidOffer(argBidModel: BidAcceptDecline): Observable<any> {
    return this.commonHttpService.post<BidAcceptDecline, BidAcceptDecline>(`${this.route}/load/bid`, argBidModel);
  }


  // Get Active load list detail
  public getActiveLoads(argPaginationModel: PaginationModel = null): Observable<any> {
    let params:any = new HttpParams();
    params = params.append('pageNumber', `${argPaginationModel.pageNumber + 1}`).set('pageSize', `${argPaginationModel.recordCount}`).set('sortBy', `${argPaginationModel.sortBy}`).set('orderBy', `${argPaginationModel.orderBy}`);
    return this.commonHttpService.get<any>(`${this.route}/load/active`, params);
  }


  // Get Active load detail
  public getActiveLoad(argLoadId: string): Observable<any> {
    return this.commonHttpService.get<any>(`${this.route}/load/active/detail?loadId=${argLoadId}`);

  }

  // Get Sent offers
  public getSentOffers(argPaginationModel: PaginationModel = null): Observable<any> {
    let params = new HttpParams();
    params = params.append('pageNumber', `${argPaginationModel.pageNumber + 1}`).set('pageSize', `${argPaginationModel.recordCount}`).set('sortBy', `${argPaginationModel.sortBy}`).set('orderBy', `${argPaginationModel.orderBy}`);
    return this.commonHttpService.get<any>(`${this.route}/load/sent-offers`, params);
  }

  public withdrawFromOffer(wom: WithdrawOfferModel): Observable<any> {
    return this.commonHttpService.post<WithdrawOfferModel, APIResponseModel<any>>(`${this.route}/load/offer-withdraw`, wom);
  }


  // Get Recent Loads
  public getRecentLoads(argPaginationModel: PaginationModel = null): Observable<any> {
    let params = new HttpParams();
    params = params.append('pageNumber', `${argPaginationModel.pageNumber + 1}`).set('pageSize', `${argPaginationModel.recordCount}`).set('sortBy', `${argPaginationModel.sortBy}`).set('orderBy', `${argPaginationModel.orderBy}`);
    return this.commonHttpService.get<any>(`${this.route}/dashboard/recent-loads`, params);
  }


  // Mark as Favourite
  public markFavourite(argFavModel: MarkFavouriteCarrierModel): Observable<any> {
    return this.commonHttpService.post<MarkFavouriteCarrierModel, MarkFavouriteCarrierModel>(`${this.route}/favorite-carriers`,
      argFavModel);
  }

  // Get Recent Loads
  public freightSpend(): Observable<any> {
    return this.commonHttpService.get<any>(`${this.route}/dashboard/frieght-spend`);
  }

  // Get Carrier Bids Listing
  public getcarrierLoadList(argPaginationModel: PaginationModel = null): Observable<any> {
    let params = new HttpParams();
    params = params.append('pageNumber', `${argPaginationModel.pageNumber + 1}`).set('pageSize', `${argPaginationModel.recordCount}`).set('sortBy', `${argPaginationModel.sortBy}`).set('orderBy', `${argPaginationModel.orderBy}`);
    return this.commonHttpService.get<any>(`${this.route}/load/carrier-offers`, params);
  }

  // Get carrier load detail
  public getCarrierLoad(argLoadId: string): Observable<any> {
    return this.commonHttpService.get<any>(`${this.route}/load/carrier-offers/detail/${argLoadId}`);

  }
}
