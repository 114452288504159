import { Component, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { StripePaymentService } from '@app/modules/account/service/stripe.service';
import { eUserType } from '@app/shared/enum/user-type';

@Component({
  selector: 'app-update-subscription',
  templateUrl: './update-subscription.component.html',
  styleUrls: ['./update-subscription.component.scss']
})
export class UpdateSubscriptionComponent implements OnInit {

  current: any;
  _accessToken = localStorage.getItem('userToken')
  userType = localStorage.getItem('userType')
  _userType = parseInt(this.userType);

  _selectedPlanId: string = undefined;
  _routeToCardDetails: boolean = false;
  _subscriptionPlans: any[] = [];


  //#endregion

  //#region properties
  get eUserType() {
    return eUserType;
  }
  //#endregion

  //#region Life cycle Hooks
  constructor(
    private renderer: Renderer2,
    private _stripeService: StripePaymentService,
    private _commonService: CommonService,
    private _router: Router,
  ) {
    this.renderer.addClass(document.body, "bg-body");
  }

  ngOnInit(): void {
    this.current = this._stripeService.currentPlan;
    if(!this.current){
      if (this._userType == eUserType.SHIPPER) {
        this._router.navigate(['shipper/edit-profile'])
      }
      else if (this._userType == eUserType.CARRIER) {
        this._router.navigate(['carrier/edit-profile'])
        
      }
      else if (this._userType == eUserType.SOLODRIVER) {
        this._router.navigate(['solo-driver/edit-profile'])
        
      }
    }
    this.getPlans();
  }

  onRedirectToUsertype() {
    if(this._userType == eUserType.SHIPPER) {
      this._router.navigate(['shipper/edit-profile'])
    }
    else if ( this._userType == eUserType.CARRIER){
      this._router.navigate(['carrier/edit-profile'])
    }
    else if (this._userType == eUserType.SOLODRIVER){
      this._router.navigate(['solo-driver/edit-profile'])
    }
  }
  
  ngOnDestroy() {
    this.renderer.removeClass(document.body, "bg-body");
  }
  //#endregion

  //#region Private methods
  private getPlans() {
    this._commonService.showPageLoader("UpdateSubscriptionComponent-getPlans")
    this._stripeService
      .subscriptionPlans(this._accessToken)
      .subscribe((res: any) => {
        if (res.statusCode == 200 || res.statusCode == 201) {
          this._subscriptionPlans = res.data;
          this._commonService.hidePageLoader("UpdateSubscriptionComponent-getPlans")

        } else {
          this._commonService.showSnakeBar(res.message);
          this._commonService.hidePageLoader("UpdateSubscriptionComponent-getPlans")

        }
      });
  }
  //#endregion

  //#region Helper Methods

  // onRedirectToRegistration() {
  //   this.RedirectToRegistration.emit(true);
  // }

  getPlanDetails(argPlanId) {
    this._selectedPlanId = argPlanId;
    this._routeToCardDetails = true;
    this._stripeService.planId = this._selectedPlanId;
    this._stripeService.accessToken = this._accessToken;
    this._stripeService._userType = this._userType;
    if (this._userType == eUserType.SHIPPER) {
      this._router.navigate(['shipper/upgrade-subscription-payment'])
    }
    else if (this._userType == eUserType.CARRIER) {
      this._router.navigate(['carrier/upgrade-subscription-payment'])
      
    }
    else if (this._userType == eUserType.SOLODRIVER) {
      this._router.navigate(['solo-driver/upgrade-subscription-payment'])
      
    }
  }

  routeToPlans() {
    this._routeToCardDetails = false;
  }

  //#endregion
}
