/**
  * @ngdoc Services
  * @name app.services.common
  * @description
  * The common service is used for getting and setting common vars used across the application
  * */

import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe, DOCUMENT } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@environment/environment';
import { COMMON_DATE_FORMAT, DM_PREFIX } from '@configs/app-settings.config';
import { genericAppMessages } from '@configs/app-message.config';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment'
import { CommonHttpService } from './common-http.service';
import { LiveLocation } from '@app/modules/solo-driver/models/location';
import { PaginationModel } from '@app/models/query-models';
import { HttpParams } from '@angular/common/http';
@Injectable()
export class CommonService {

  public ShipmentsLoadFilter = ['pickupDate', 'deliveryDate', 'minPrice', 'maxPrice', 'origin', 'destination', 'loadServiceType',
    'pickupDropoffType', 'trailerTypeId', 'trailerSizeId', 'weight', 'duration', 'expiryDate', 'volume',
    'loads', 'shipmentType', 'procedure', 'status', 'shipmentStatus'];
    public rfpOpenLoadFilter=['duration','expiryDate','volume','loads']
    public rfpSentOfferFilter=['origin', 'destination','duration','expiryDate','volume','loads','shipmentType', 'procedure','weight']

  @Inject(DOCUMENT) private document: any;
  public _loader = new BehaviorSubject<{ Identifier, IsLoading }>(undefined)


  constructor(
    public snackBar: MatSnackBar,
    public router: Router,
    private commonHttpService: CommonHttpService,
    private date: DatePipe,

  ) { }

  /**
  * Get api base url
  * @name getApiBaseUrl
  * @param  none
  * @return {String}
  */
  getApiBaseUrl() {
    return environment.api_url;
  }


  lowerCaseUnderScore(str = "") {
    if (typeof (str == 'string')) {
      return str.replace(/\s+/g, '_').toLowerCase()
    }
  }

  transErrorResponse(response) {
    response = response ? response.json() : {};
    return response;
  }

  /**
  * Show snake bar message
  * @name showSnakeBar
  * @param  msg{String,action:action to show}
  * @return none
  */
  showSnakeBar(msg, action = null, duration = 2000) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }


  /**
  * Showing common error message
  * @name commonSnakeBar
  * @param  none
  * @return none
  */
  commonSnakeBar() {
    this.showSnakeBar(genericAppMessages.httpError, 'Done');
  }

  showSuccessSnakeBar(msg, action = null, duration = 2000) {
    this.snackBar.open(msg, action, {
      duration: duration,
      panelClass: ['text-success']
    });
  }

  showErrorSnakeBar(msg, action = null, duration = 2000) {
    this.snackBar.open(msg, action, {
      duration: duration,
    });
  }
  /**
  * navigate user to given url
  * @name navigate
  * @param  url {String}
  * @return none
  */
  navigate(url) {
    this.router.navigate([url]);
  }

  addClassToBody(className) {
    this.document.body.classList.add(className);
  }

  removeClassFromBody(className) {
    this.document.body.classList.remove(className);
  }

  navigateSkipLocation(url) {
    this.router.navigateByUrl(url, { skipLocationChange: true });
  }


  /**
  * Transform date
  * @name transFormDate
  * @param  date, format(Date Format)
  * @return String
  */
  transFormDate(date, format = null) {
    let dateFormat = COMMON_DATE_FORMAT;
    if (format != null) {
      dateFormat = format;
    }
    return this.date.transform(date, dateFormat);
  }


  setLocalStorage(key, value) {
    window.localStorage.setItem(DM_PREFIX + key, value);
  }

  removeLocalStorage(key) {
    window.localStorage.removeItem(DM_PREFIX + key);
  }

  getLocalStorage(key) {
    return window.localStorage.getItem(DM_PREFIX + key);
  }

  componentDestroyed(component: OnDestroy) {
    const oldNgOnDestroy = component.ngOnDestroy;
    const destroyed$ = new ReplaySubject<void>(1);
    component.ngOnDestroy = () => {
      oldNgOnDestroy.apply(component);
      destroyed$.next(undefined);
      destroyed$.complete();
    };
    return destroyed$;
  }

  validateIsEmpty(input, list: Array<String> = []) {
    if ((typeof input).toLowerCase() == 'boolean') {
      return false;
    }
    else if (input != undefined && input != null && input != '') {
      if (input instanceof Date) {
        return false;
      }
      else if (Array.isArray(input) && input.length != 0) {
        return false;
      }
      else if ((typeof input).toLowerCase() == 'object') {
        let isedit = false;
        Object.keys(input).map(key => {
          if (list.indexOf(key.toString()) == -1 && (input[key] != undefined && input[key] != null && input[key] != '')) {
            isedit = true;
          }
        });
        if (isedit) {
          return false;
        }
        else {
          return true;
        }
      }
      else if ((typeof input).toLowerCase() == 'string' && input.length != 0) {
        return false;
      }
      else if ((typeof input).toLowerCase() == 'number') {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  DeleteAuthToken(): void {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('PermissionsList');
    localStorage.removeItem('first_path_user_setting');
    this.router.navigate(['/login']);
  }

  handleErrorMessage(msg) {
    if (!this.validateIsEmpty(msg)) {
      this.snackBar.open(msg, null, { duration: 6000 });
    } else {
      this.snackBar.open('Error occured', null, { duration: 6000 });
    }
  }

  handleApiError(err) {
    if (!this.validateIsEmpty(err) && !this.validateIsEmpty(err.Message)) {
      const errorMsgStr: string = (<string>(err.Message)).toLowerCase();
      if (errorMsgStr === 'tokenisrequired' || errorMsgStr === 'tokenisinvalid' || errorMsgStr === 'tokenexpired') {
        this.snackBar.open('Session Expired', null, { duration: 5000 });
        this.DeleteAuthToken();
      } else {
        this.snackBar.open(err.Message, null, { duration: 5000 });
      }
    } else {
      this.handleErrorMessage('Error occured');
    }
  }

  showPageLoader(argIdentifier: string) {
    if ((argIdentifier || '')) {
      this._loader.next({ Identifier: argIdentifier, IsLoading: true })
    }
  }

  hidePageLoader(argIdentifier: string) {
    if ((argIdentifier || '')) {
      this._loader.next({ Identifier: argIdentifier, IsLoading: false })
    }
  }
  setPageLoadingStatus() {
    return this._loader.asObservable();
  }

  restrictSpecialChar(charcode: number) {
    return ((charcode > 64 && charcode < 91) || (charcode > 96 && charcode < 123) || charcode == 8 || charcode == 32 || (charcode >= 48 && charcode <= 57));
  }

  dtFormate(date) {
    return moment(date).format('MM/DD/YYYY');
  }

  public getlocation(params: String, argDriverId: any, argLoadId: any): Observable<any> {
    return this.commonHttpService.get<any>(`${params}${argDriverId}?loadId=${argLoadId}`)

  }

  public getlocations(params: String, argDriverId: any, argLoadId: any, argLastId: any): Observable<any> {
    return this.commonHttpService.get<any>(`${params}${argDriverId}?loadId=${argLoadId}&lastId=${argLastId}`)

  }
  public getDashboardActiveLoads(argPaginationModel: PaginationModel = null,id:number,loadType:string): Observable<any>{
    let params = new HttpParams();
    params = params.append('pageNumber', `${argPaginationModel.pageNumber + 1}`).set('pageSize', `${argPaginationModel.recordCount}`);
    return this.commonHttpService.get<any>(`${loadType}/load/dashboard/active`, params);
  }

  public getChatBoxLoads(loadType: string):Observable<any>{
    return this.commonHttpService.get<any>(`${loadType}/load/chatbox-loadlisting`);
  }
  public location(argLiveLocation: LiveLocation): Observable<any> {
    return this.commonHttpService.post<LiveLocation, LiveLocation>(`${'solodriver/send-location'}`, argLiveLocation)
  }

  public convertString(string, type) {
    if (type === 'SPLIT') {
      string = string.replace(/([A-Z])/g, ' $1').replace(/^./, (x) => x.toUpperCase())
      if (string.includes('Trailer Type Id') || string.includes('Trailer Size Id')) {
        return string.replace(/Id/i, '')
      } else
        return string
    } if (type === 'JOIN') {
      string = string.replace(/ /g, '').replace(/^./, (x) => x.toLowerCase())
      if (string.includes('trailerType') || string.includes('trailerSize')) {
        return string.concat('Id')
      } else
        return string
    }
  }
}

