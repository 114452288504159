import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CommonService } from '@app/core/services/common.service';
import { TrailerSize, TrailerType } from '@app/modules/shipper/components/shipments/load/add-edit-load/add-edit-load.component';
import { procedure, rfpDuration } from '@app/modules/shipper/enums/rfp-enums';
import { MasterService } from '@app/modules/shipper/services/master.service';
import { FilterService } from '@app/shared/services/fillter.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})

export class FilterComponent implements OnInit {
  @ViewChild("location") location: ElementRef;
  @ViewChild('factorLocation') factorLocation: any;
  @ViewChild("factorLocation2") factorLocation2: ElementRef;
  @ViewChild("factorCountry") factorCountry: ElementRef;
  @ViewChild("factorZipCode") factorZipCode: ElementRef;


  // @Output() filterSource:any;
  @Output() filterAPI: boolean = false;
  @Input() name
  @Input() context

  showhide: boolean = false;
  _trailerSizes = new Array<TrailerSize>();
  _trailerTypes = new Array<TrailerType>();
  _ratingScore: number = 0;
  _totalstar = 5;

  constructor(
    private _masterService: MasterService,
    private _formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _filterService: FilterService,
    private _commonService: CommonService,
  ) { }

  //#region maps members
  private geoCoder;
  latitude: number = undefined;
  longitude: number = undefined;
  //#endregion

  //form region start
  _form: FormGroup = this._formBuilder.group({
    pickupDate: new FormControl(""),
    deliveryDate: new FormControl(""),

    minPrice: new FormControl(""),
    maxPrice: new FormControl(""),

    origin: new FormControl(""),
    destination: new FormControl(""),

    loadServiceType: new FormControl(""),
    pickupDropoffType: new FormControl(""),
    trailerTypeId: new FormControl(""),
    trailerSizeId: new FormControl(""),
    weight: new FormControl(""),

    duration: new FormControl(""),
    expiryDate: new FormControl(""),
    volume: new FormControl(""),
    loads: new FormControl(""),

    shipmentType: new FormControl(""),
    procedure: new FormControl(""),

    status: new FormControl(""),
    shipmentStatus: new FormControl(""),

    city: new FormControl(""),
    state: new FormControl(""),
    memberSince: new FormControl(""),
    rating: new FormControl(""),
  });

  //region ended

  //helper region start
  ngOnInit(): void {
    this.getTrailerDetails();
    const btn = setInterval(() =>{
       if(this.context && this.context.showAppliedFilterOption && this.context.showAppliedFilterOption.length == 0 ){  
         this.context._filter = false
       }
    },500);
  }
  
  onChangeRating(argEvent:any){
    this._ratingScore = argEvent.newValue;
    this._form.get('rating').setValue(this._ratingScore)
  }

  filltertoggle() {
    this.showhide = !this.showhide;
    if (this.context && this.context.showAppliedFilterOption && this.context.showAppliedFilterOption.length >= 0) {
      for (let key in this._form.value) {
        let findFormName = this.context.showAppliedFilterOption.find((ele) => ele == key)
        if (key != findFormName) {
          this._form.controls[key].reset();
        }
      }
    }
  }

  applyFilter() {
    if (this.context) {
      this.filterAPI = true;
      this.showhide = !this.showhide;
      let _filterData = {}
      if (this._form.valid) {
        for (let key in this._form.value) {
          if (this._form.value.hasOwnProperty(key) && this._form.value[key]) {
            _filterData = {
              ..._filterData,
              [key]: this._form.value[key]
            }

          }
        }
        if (Object.keys(_filterData).length) {
          this.context.filterTable(_filterData, this.filterAPI);
          this._commonService.showSuccessSnakeBar('FilterApplied')
        } else {
          this.showhide = !this.showhide;
          this._commonService.showErrorSnakeBar('Please Choose Any Field to Apply Filter ')
        }
      }


    }
  }

  reload() {
    this.context.filterOptions = [];
    this.context.showAppliedFilterOption.length=0;
    this.context._filter = false;
    this.context.filterAPI = false;
    this.context.ngOnInit();
  }
  reset() {
    this._form.reset();
    this.context.filterOptions = [];
    this.context.showAppliedFilterOption.length=0;
    this.context._filter = false
    this.context.ngOnInit();
  }

  close() {
    this.showhide = !this.showhide;
    if (this.filterAPI == false) {
      this._form.reset();
    }
  }

  //region ended

  onCheckEntry(event: KeyboardEvent) {
    if (event.which < 48 || event.which > 57) {
      event.preventDefault();
    } else {
      if (event.which < 48 || event.which > 57) {
        if (event.which !== 46) {
          event.preventDefault();
        }
      }
    }
  }
  //private region start
  private getTrailerDetails() {
    this._masterService.getTrailerTypes().subscribe((res) => {
      if (res.data) {
        this._trailerTypes = res.data;
      }
    });

    this._masterService.getTrailerSizes().subscribe((res) => {
      if (res.data) {
        this._trailerSizes = res.data;
      }
    });
  }

  mapAutoPopulateForFactorCompany(element: string) {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(
        this[element].nativeElement,
        {
          types: [],
          //Places to be shown only
          componentRestrictions: { country: [] },
        }
      );

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.getAddressFieldsForFactorCompany(this.latitude, this.longitude);
        });
      });
    });
  }

  getAddressFieldsForFactorCompany(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            for (var i = 0; i < results[0].address_components.length; i++) {
              var address = results[0].address_components[i];

              if (address.types[0] == "country") {
                this._form.get("factorCountry").setValue(address.long_name);
                this.factorCountry.nativeElement.focus();
              } else if (address.types[0] == "postal_code") {
                this._form.get("factorZipcode").setValue(address.short_name);
                this.factorZipCode.nativeElement.focus();
              } else if (address.types[0] == "administrative_area_level_1")
                // State
                this._form.get("factorState").setValue(address.long_name);
              else if (address.types[0] == "locality")
                this._form.get("factorCity").setValue(address.long_name); // City
            }
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }
  get rfpDuration() {
    return rfpDuration;
  }
  get procedure() {
    return procedure;
  }

}
