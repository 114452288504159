import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { JobDetailComponent } from '@app/modules/solo-driver/components/job-offer/job-detail/modal-job-detail.component';
import { ShipmentLoadDetail } from '@app/modules/solo-driver/models/solo-driver.model';
import { eUserType } from '@app/shared/enum/user-type';

@Component({
  selector: 'app-bid-confirm',
  templateUrl: './bid-confirm.component.html',
  styleUrls: ['./bid-confirm.component.scss']
})
export class BidConfirmComponent implements OnInit {

  onDataRecieved = new EventEmitter();
  userType:any;
  constructor(
    public dialogRef: MatDialogRef<BidConfirmComponent>,
    private _domSanitizer: DomSanitizer,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data.description = this._domSanitizer.bypassSecurityTrustHtml(this.data.description);
  }

  ngOnInit(){
    this.userType = localStorage.getItem('userType')
  }

  onSelectAutohire(): void {
    this.onDataRecieved.emit(true);
    this.dialogRef.close();
  }

  onAuthoried(){
      const dialogRef = this.dialog.open(JobDetailComponent, {
        width: '692px', 
        data: this.data && this.data.componentData,
      }); 
    this.dialogRef.close();
  }

  onSelectBid(): void {
    this.dialogRef.close();
    this.onDataRecieved.emit(false);
  }

  closeDialog():void{
    this.dialogRef.close();
  }
}
