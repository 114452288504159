export enum rfpDuration{
    Quarterly = 1,
    Half_Yearly = 2,
    Annually = 3,
    Custom = 4
}

export enum shipmentType{
    Single = 1,
    Multiple = 2
}

export enum procedure{
    Live_Live = 1,
    Drop_Live = 2,
    Live_Drop = 3,
    Drop_Drop = 4
}