/**
 * @module SharedModule
 * @description
 * This is the shared module of the application.
 * It contain the application related dependencies that can be shared across the applications
 */

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import MaterialComponentsModule from '@app/modules/theme/material-components.module';
import { NoRecordFoundComponent } from './no-record-found/no-record-found.component';
import { ConfirmModalComponent } from './shared-modal/confirm-modal/confirm-modal.component';
import { BidConfirmComponent } from './shared-modal/bid-confirm/bid-confirm.component';
import { ConfirmResolveDisputeComponent } from './shared-modal/confirm-resolve-dispute/confirm-resolve-dispute.component';
import { CarrierProfileComponent } from './components/carrier-profile/carrier-profile.component';
import { ShipperProfileComponent } from './components/shipper-profile/shipper-profile.component';
import { CommonModule } from '@angular/common';
import { RatingModule } from 'ng-starrating';
import { ChartsModule } from 'ng2-charts';
import { AssignedStaffComponent } from './components/assigned-staff/assigned-staff.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ChatBoxComponent } from './components/chat-box/chat-box.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FilterComponent } from './components/load table filter/filter/filter.component';
import { UpdateSubscriptionComponent } from './components/update-subscription/update-subscription.component';
import { NgxStripeModule } from 'ngx-stripe';
import { StripePaymentService } from '@app/modules/account/service/stripe.service';
import { UpdateSubscriptionPaymentComponent } from './components/update-subscription-payment/update-subscription-payment.component';
import { CommanProfileComponent } from './components/comman-profile/comman-profile.component';

@NgModule({
   declarations: [NoRecordFoundComponent, ConfirmModalComponent, BidConfirmComponent,
      ConfirmResolveDisputeComponent, CarrierProfileComponent, ShipperProfileComponent, AssignedStaffComponent,
       LoaderComponent, ChatBoxComponent, FilterComponent, UpdateSubscriptionComponent, UpdateSubscriptionPaymentComponent, CommanProfileComponent],
   imports: [FormsModule, ChartsModule, ReactiveFormsModule, RatingModule, MaterialComponentsModule, CommonModule, OwlDateTimeModule, OwlNativeDateTimeModule,
      NgxStripeModule.forRoot('pk_test_51HS27CGULtR73YOeci3Ls3Dw99rONWrGfoI29hgacBtOQpBoOjbmRMvDviOlwdChgblN0ACSe27GqzIF9yfarNcL00s4JJvTFW')],
   exports: [NoRecordFoundComponent, LoaderComponent, ChartsModule, FormsModule, RatingModule, ReactiveFormsModule,
      MaterialComponentsModule, ShipperProfileComponent, CarrierProfileComponent,CommanProfileComponent, AssignedStaffComponent, CommonModule,FilterComponent,
      ChatBoxComponent,
   ],
   providers: [StripePaymentService],
})
export class SharedModule { }
