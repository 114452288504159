
/**
 * @name appMessageBase
 * @description
 * This is config file, it include only global application related messages
 * Define Settings the object way(based on requirement):
 * @example {paramsName}:{value}
 * @constant appMessageBase
 * @type {CommonBase}
 */

import { CommonBase } from '@core/interfaces/common-base';

export const genericAppMessages = Object.freeze({
  httpError: 'An error occurred while fetching the response. Please try again.',
});
