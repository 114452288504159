<div class="main-modal" [formGroup]="_form" [ngClass]="{'submitted': _isSubmitted === true}" (ngSubmit)="onSubmit()"
    autocomplete="off" novalidate>
    <div class="modal_header">

        <h2 class="modal_title">Assign Truck <span class="f-12 fw-400 text-grey-light">(LYNK ID#: {{data.lynk}})</span>
        </h2>
        <div class="material-icons close_modal" (click)="close()">close</div>
    </div>

    <div mat-dialog-content class="modal-body">
        <div class="mb-0 mt-3">
            <div class="form_row" *ngIf="data.autoHire">
                <span class="status-text label-badge m-0 green_text">Bid Price:
                    {{data.targetPrice}}</span>
            </div>
            <div class="form_row">
                <label class="label mb-2">Select Truck</label>
                <div class="input_block select-border-box-outer">
                    <mat-form-field class="w-100">
                        <mat-label>Select Truck</mat-label>
                        <mat-select placeholder="Select" class="select-border-box input_fld" formControlName="truckId">
                            <mat-option *ngFor="let iTruck of _truckList" [value]="iTruck.id">
                                {{iTruck.mcNumber}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="form_row">
                <label class="label mb-2">Select trailer</label>
                <div class="input_block select-border-box-outer">
                    <mat-form-field class="w-100">
                        <mat-label>Select Trailer</mat-label>
                        <mat-select placeholder="Select" class="select-border-box input_fld" formControlName="trailerId">
                            <mat-option *ngFor="let iTrailer of _trailerList" [value]="iTrailer.id">
                                {{iTrailer.trailerNumber}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>
                <label class="label mb-2" [ngStyle]="{'color':'red'}" [hidden]="!showMessage" >No matching trailer found!</label>
            </div>
            <div class="form_row">
                <label class="label mb-2">Select driver</label>
                <div class="input_block">
                    <div class="input_hover input-border input-border-with-icon">
                        <mat-form-field class="w-100" appearance="fill">
                            <div class="input-icon-front"><span class="material-icons green_text">search</span></div>

                            <input class="input_fld" matInput placeholder="Search by name" formControlName="driverId"
                                [matAutocomplete]="autoCompleteDrivers">

                            <mat-autocomplete #autoCompleteDrivers="matAutocomplete">
                                <mat-option *ngFor="let iDriver of _driverList" [value]="iDriver.name"
                                    (click)="selectDriver(iDriver)">
                                    {{iDriver.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- <div class="form_row">
                <label class="label mb-2">Add reason</label>
                <div class="input_block">
                    <div class="input_hover input-border">
                        <mat-form-field class="w-100">
                            <textarea class="input_fld md-textarea" matInput placeholder="Enter the description here..."
                                formControlName="description"></textarea>
                        </mat-form-field>
                    </div>
                    <span class="text-right f-12 d-black d-block mt-2"><span class="fw-500">110 characters</span> <span
                            class="text-grey-light">(minimum 200 characters)</span></span>
                </div>
            </div> -->
        </div>
    </div>


    <div class="modal-footer mt-3 mb-3 no-border">
        <button mat-button class="btn transparent_btn large_btn huge-btn" (click)="close()">
            <i class="material-icons start">close</i>
            Cancel
        </button>
        <button mat-button class="btn sml_rounded_btn large_btn green_btn huge-btn ml-2" (click)="onSubmit()">
            Submit <i class="material-icons rotate-right icon-right">keyboard_backspace</i>
        </button>
    </div>
</div>