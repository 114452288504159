<div class="profile-box-area mb-1">
    <div class="profile-box-img">
       
        <img *ngIf="staffInfo.profileUrl!==' '" src={{staffInfo.profileUrl}} onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';"/>
        <img *ngIf="staffInfo.profileUrl===' '" src="assets/images/person-ic.jpg" onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';"/>
       
        <!-- <ng-container *ngIf="staffInfo.profileUrl"> <img src={{staffInfo.profileUrl}} /></ng-container>
        <ng-container *ngIf="!staffInfo.profileUrl"> <img src="assets/images/person-ic.jpg" /></ng-container> -->
    </div>
    <div class="profile-box-info">
        <h3>{{staffInfo.name}}</h3>
        <h5>{{staffInfo.branchName}}, {{staffInfo.branchLocation}}</h5>

        <label class="d-flex align-items-center w-100 icon-with-label">
            <span class="material-icons green_text mr-2">
                email
            </span>
            {{staffInfo.emailId}}
        </label>
        <label class="d-flex align-items-center w-100 icon-with-label">
            <span class="material-icons green_text mr-2">
                phone
            </span>
            {{staffInfo.phoneNo}}
        </label>
    </div>
</div>