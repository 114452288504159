import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewChecked,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { PubNubAngular } from "pubnub-angular2";

@Component({
  selector: "app-chat-box",
  templateUrl: "./chat-box.component.html",
  styleUrls: ["./chat-box.component.scss"],
})
export class ChatBoxComponent implements OnInit, AfterViewChecked, OnDestroy {
  pubnub: PubNubAngular;
  messages: any[];
  @ViewChild("scrollMe") private myScrollContainer: ElementRef;

  public channel: string;
  private sender: any;
  applyClass = false;
  @Input() showContractDetail = true;
  listener: any;
  @Input() public data: any;
  @Output() childToParent = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {
    this.channel = `${this.data.channel}x`;
    this.sender = this.data.senderInfo;
    this.pubnub = new PubNubAngular();
    this.pubnub.init({
      publishKey: "pub-c-dde615cd-e8b7-4dd1-a8b9-266d6bbd9a5b",
      subscribeKey: "sub-c-671f361c-d985-418a-a617-c9d00261da35",
      //orignal keys
      // publishKey: "pub-c-f4dd5c59-71f8-49a7-862b-42a6005ad475",
      // subscribeKey: "sub-c-5b2e73fe-ec71-11ea-8cd7-2246479f46d6",
    });
    this.pubnub.subscribe({
      channels: [this.channel],
      triggerEvents: ["message"],
      autoload: 10,
    });
    this.pubnub.addListener({
      message(messageEvent) {
      },
    });
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  ngOnDestroy() {
    this.pubnub.removeListener(this.listener);
    this.pubnub.unsubscribe(this.channel);
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  sendMessage(message: string) {
    if (message.trim() !== "") {
      this.pubnub.publish({
        // tslint:disable-next-line: max-line-length
        channel: this.channel,
        ttl: 0,
        message: {
          sender: this.sender,
          message,
          date: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
          dateTime: Date.now().toString(),
        },
      });
      // await this.getMessages();
    }
  }

  getLength(message: string) {
    this.applyClass = message.length > 0;
  }

  closeChatDialog() {
    this.childToParent.emit(false);
  }
}
