import { Injectable } from '@angular/core';
import { CommonHttpService } from '@app/core/services/common-http.service';
import { Observable } from 'rxjs';
import { PaginationModel } from '@app/models/query-models';
import { HttpParams } from '@angular/common/http';
import { DriverModel } from '../models/driver.model';
import { LiveLocation } from '../models/location';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  private route = 'solodriver/drivers'
  // private jobHistory = ' carrier/drivers/7/job-history'

  constructor( private commonHttpService: CommonHttpService) { }
  
  public add(argDriverModel:DriverModel):Observable<any>{
    return this.commonHttpService.post<DriverModel, DriverModel>(`${this.route}`, argDriverModel)
  }

  public getJobHistoryList(argDriverId:any , argPaginationModel: PaginationModel = null):Observable<any>{
    let  params = new HttpParams();
    params= params.append('pageNumber', `${argPaginationModel.pageNumber+1}`).set('pageSize', `${argPaginationModel.recordCount}`);
    return this.commonHttpService.get<any>(`${this.route}/${argDriverId}/job-history`,params)
  }
  
  public getList(argPaginationModel: PaginationModel = null):Observable<any>{
    let  params = new HttpParams();
    params= params.append('pageNumber', `${argPaginationModel.pageNumber+1}`).set('pageSize', `${argPaginationModel.recordCount}`);
    return this.commonHttpService.get<any>(`${this.route}`,params)
  }

  public get(argDriverId: string):Observable<any>{
    return this.commonHttpService.get<any>(`${this.route}/${argDriverId}`);
  }

  
  public update(argDriverModel:DriverModel):Observable<any>{
    return this.commonHttpService.put<DriverModel,DriverModel>(`${this.route}/${argDriverModel.id}`,argDriverModel)
  }
 
  public delete(argDriverId:string):Observable<any>{
    return this.commonHttpService.delete(`${this.route}/${argDriverId}`)
  }

  public search(argKeyword:any):Observable<any>{
    const queryString:any = {keyword : argKeyword}
    return this.commonHttpService.get<any>(`${this.route}/Keyword`,queryString)
  }
}
