import * as firebase from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging/sw";
import { initializeApp } from "firebase/app";


const config = initializeApp( {
  apiKey: "AIzaSyBfNKtCxz_D6768GodbVBm-kzlLnezVmCQ",
  authDomain: "shipping-65aad.firebaseapp.com",
  databaseURL: "https://shipping-65aad-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "shipping-65aad",
  storageBucket: "shipping-65aad.appspot.com",
  messagingSenderId: "1016848226709",
  appId: "1:1016848226709:web:9d77c0b94efcc8548fbe9c",
  measurementId: "G-RE55VB9HPE"
});
// const messaging = getMessaging(config);
// exporting firebase, messaging for calling in appComponent

export { firebase, config  };

