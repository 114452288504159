import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonHttpService } from '@app/core/services/common-http.service';
import { Observable } from 'rxjs';
import { PaymentModel } from '../components/card-detail/card-detail.component';


@Injectable()
export class StripePaymentService {

  private basePath = 'stripe/';
  public planId: string;
  public accessToken: string;
  public _userType: Number;
  public currentPlan: any;

  constructor(
    private _http: HttpClient) {
  }

  //Subscription Plans

  subscriptionPlans(argAccessToken): Observable<any> {
    const headersConfig: any = { 'Content-Type': 'application/json', Accept: 'application/json' };
    if (argAccessToken) {
      headersConfig.Authorization = `Bearer ${argAccessToken}`;
    }
    return this._http.get<any>(`${this.basePath}subscription-plans`, { headers: headersConfig });
  }

  //Subscription Plans

  payment(argPaymentModel: PaymentModel, argAccessToken): Observable<any> {
    const headersConfig: any = { 'Content-Type': 'application/json', Accept: 'application/json' };
    if (argAccessToken) {
      headersConfig.Authorization = `Bearer ${argAccessToken}`;
    }

    return this._http.post<PaymentModel>(`${this.basePath}payment`, argPaymentModel, { headers: headersConfig });
  }
}
