

export enum eBidAcceptDecline {
    'Accept' = 1,
    'Decline' = 0
}

export enum eStatus {
    END = 'End',
    ACCEPTED = 'Accepted',
    DISPUTE = 'Dispute'
}

export enum ePickUpDropType {
    APPOINTMENT = 'Appointment',
    FCFS = 'FCFS'
}

export enum eUnit {
    'LBS' = 2,
    'KG' = 1
}


export enum eServiceType {
    'Full' = 1,
    'Partial' = 2
}

export enum eStopType {

    'Pickup' = 1,
    'StopPickup' = 2,
    'StopDelivery' = 3,
    'Unload' = 4,
    'Drop' = 5
}

export enum eStopageSubType {
    'FCFS' = 1,
    'Appointment' = 2 
}

 export enum ePickupDropoffType{
     'Single'=  1,
     'Multiple' = 2

 }

export enum eAcceptDeclineCost {
    'Default' = 0,
    'Approve' = 1,
    'Decline' = 2
}

export enum eBranchRoleType{
    'BranchAdmin' = 1,
    'FinancialAdmin' = 2,
    'StaffAdmin' = 3
}
