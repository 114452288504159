import { Component } from '@angular/core';
import { CommonService } from './core/services/common.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { firebase, config } from './firebase-config'
import { NotificationTokenModel } from './shared/models/response-model';
import { NotificationService } from './shared/services/notification.service';
import  "firebase/compat/messaging";
import {  getMessaging,getToken } from "firebase/messaging";
// import * as foo from "firebase/messaging";



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Shipping Market Place';
  tokenSend: any;
  messaging
  constructor(
    private _commonService: CommonService,
    private _localStorageService: LocalStorageService,
    private notificationService: NotificationService
  ) { }

   ngOnInit() {
    this.messaging = getMessaging(config)
    let context = this;
    window.addEventListener("beforeunload", function (e) {
      //this._localStorageService.deleteValueByKey('userToken');
      //window.localStorage.removeItem('userToken')
    });

    this.displayNotification();
    
  }

  displayNotification() {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        getToken(this.messaging, {
          vapidKey:   
            "BCPXBOkprlBT7hKurSj7x3orjlLP79S-RbP-cBHbs6_PlMEKZV4KpqfiMT77n-VbfyEwBwugEnmQ21WH4utCYNA",
        })
          .then((currentToken) => {
            if (currentToken && localStorage.getItem('userToken')) {
              let token = localStorage.getItem('notificationToken')
                  if (!token) {
                      this.tokenSend = currentToken
                      localStorage.setItem('notificationToken', currentToken);
                      this.sendToken()
                    
                  } else if (token!=currentToken) {
                    this.tokenSend = currentToken
                    localStorage.setItem('notificationToken', currentToken);
                    this.sendToken();
                  } else {
                      return;
                      
                  }
              
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
              // ...
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // ...
          });
      } else {
        console.log("Unable to get permission to notify.");
      }
    });
     
  }
  sendToken() {
    if (this.tokenSend) {
      let notificationModel = new NotificationTokenModel();
      notificationModel.os = "web";
      notificationModel.token = this.tokenSend;
      this.notificationService.sendToken(notificationModel).subscribe((res) => {
        if (res.statusCode === 200) {
          console.log('Success')
        }
      })
    }
  }
}
