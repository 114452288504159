import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { UserService } from '@app/core/services/user.service';
import { StripePaymentService } from '@app/modules/account/service/stripe.service';
import { eUserType } from '@app/shared/enum/user-type';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeFactoryService, StripeInstance, StripeService } from 'ngx-stripe';

@Component({
  selector: 'app-update-subscription-payment',
  templateUrl: './update-subscription-payment.component.html',
  styleUrls: ['./update-subscription-payment.component.scss']
})
export class UpdateSubscriptionPaymentComponent implements OnInit {


  //#region Subscription Members
  _accessToken: string;
  _planId: string;
  _userType: Number;


  _processingPayment = false;
  _paymentSuccessfull = false;

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        fontWeight: "300",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "18px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {};

  stripeTest: FormGroup;
  stripe: StripeInstance;
  token: string;

  //#endregion

  //#region Life cycle hooks
  constructor(
    private _renderer: Renderer2,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private _userService: UserService,
    private _router: Router,
    private _localStorageService: LocalStorageService,
    private _stripePaymentService: StripePaymentService,
    private _commonService: CommonService,
    private stripeFactory: StripeFactoryService
  ) { }
  
  
  get eUserType() {
    return eUserType;
  }

  ngOnInit(): void {
    this._accessToken = this._stripePaymentService.accessToken;
    this._planId = this._stripePaymentService.planId;
    this._userType = parseInt(localStorage.getItem('userType'));
    this._renderer.addClass(document.body, "bg-body");
    this.stripe = this.stripeFactory.create(
      "pk_test_51HS27CGULtR73YOeci3Ls3Dw99rONWrGfoI29hgacBtOQpBoOjbmRMvDviOlwdChgblN0ACSe27GqzIF9yfarNcL00s4JJvTFW"
    );
    this.stripeTest = this.fb.group({
      name: ["", [Validators.required]],
    });
    this.cardOptions.hidePostalCode = true;
    this.noToken()
  }


  noToken() {
    if (!this._accessToken || !this._planId) {
      this.redirectToProfile()
    }
  }

  ngOnDestroy() {
    this._renderer.removeClass(document.body, "bg-body");
  }
  //#endregion


  //#region Helper functions

  createToken(): void {
    this._commonService.showPageLoader("UpdateSubscriptionPaymentComponent-createToken");
    this._processingPayment = true;
    const name = this.stripeTest.get("name").value;
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result) {
          // Use the token
          this.token = result.token.id;
          this._commonService.hidePageLoader("UpdateSubscriptionPaymentComponent-createToken");
          this.onSubmit();
        } else if (result.error) {
          // Error creating the token
          this.token = "";
          this._commonService.hidePageLoader("UpdateSubscriptionPaymentComponent-createToken");
        }
      });
  }

  onSubmit() {
    const paymentModel = new PaymentModel();
    paymentModel.planId = this._planId;
    paymentModel.tokenId = this.token;
    this._commonService.showPageLoader("UpdateSubscriptionPaymentComponent-onSubmit");
    this._stripePaymentService
      .payment(paymentModel, this._accessToken)
      .subscribe(
        (res) => {
          if (res.statusCode == 200 || res.statusCode == 201) {
            this._paymentSuccessfull = true;
            this._processingPayment = false;
            this._commonService.showSnakeBar("Payment Successfull");
            setTimeout(()=>this.redirectToProfile(),1000)
          } else {
            this._processingPayment = false;
            this._commonService.showSnakeBar(res.message);
          }
          this._commonService.hidePageLoader("UpdateSubscriptionPaymentComponent-onSubmit");
        },
        null,
        () => {
          this._processingPayment = false;
          this._commonService.hidePageLoader("UpdateSubscriptionPaymentComponent-onSubmit");
        }
      );
  }

  redirectToProfile() {
    if (this._userType == eUserType.SHIPPER) {
      this._router.navigate(['shipper/profile'])
    }
    else if (this._userType == eUserType.CARRIER) {
      this._router.navigate(['carrier/profile'])
      
    }
    else if (this._userType == eUserType.SOLODRIVER) {
      this._router.navigate(['solo-driver/profile'])
      
    }
  }

}

export class PaymentModel {
  planId: string = undefined;
  tokenId: string = undefined;
}
