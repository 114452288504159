<div mat-dialog-content>
    <div class="text-center mb-0 mt-3 position-relative">
      <span class="material-icons position-absolute close-icon-modal" (click)="closeDialog()">close</span>
      <img src="assets/images/check-icon.svg" alt="" class="mb-3">
      <star-rating value="{{data.title}}" totalstars={{_totalstar}} checkedcolor="#51af57"
      uncheckedcolor="#ddd" size="24px" readonly='true'></star-rating>
     
      <div>
        <p class="cursor-pointer green_text" [innerHTML]="this.data.description"></p>
      </div>
  
  
    </div>
  </div>
  <div mat-dialog-actions class="d-flex align-center justify-content-center mt-3 mb-4">
    <button (click)="closeDialog()" mat-button class="btn rounded_btn green_btn mr-2 small_btn px-4">Ok</button>
  </div>
