import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '@app/core/services/common.service';
import { eOfferAcceptDecline } from '@app/modules/carrier/enums/offers-accepted.enum';
import { eUnit } from '@app/modules/shipper/enums/shipper-enum';
import { GetHiredDetail } from '@app/modules/solo-driver/models/get-hired.model';
import { OfferAcceptDecline } from '@app/modules/solo-driver/models/offer-accept-model';
import { LoadService } from '@app/modules/solo-driver/service/load.service';
import { OfferAcceptService } from '@app/modules/solo-driver/service/offer-accept.service';
import { AssignTruckComponent } from '../assign-truck/assign-truck.component';
import { DeclineOfferComponent } from '../decline-offer/decline-offer.component';
import { OfferConfirmationComponent } from '../offer-confirmation/offer-confirmation.component';

@Component({
  selector: 'app-modal-job-detail',
  templateUrl: './modal-job-detail.component.html',
  styleUrls: ['./modal-job-detail.component.scss']
})
export class JobDetailComponent implements OnInit {
  _showData = false;
  _showData2 = false;
  bookNow = false;
  _trailerType: string = '';
  _trailerSize: string = '';
  _driverName: string = '';
  _isSubmitted: boolean;


  //#region members
  @Output() _offerAccepted = new EventEmitter();
  @Output() _offerDeclined = new EventEmitter();
  _referenceNumbers = new Array<any>();


  get eUnit() { return eUnit }

  constructor(public dialogRef: MatDialogRef<JobDetailComponent>,
    public dialog: MatDialog,
    private _commonService: CommonService,
    private _loadService: LoadService,
    private _offerAcceptService: OfferAcceptService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data.trailerSizeId.id === parseInt(localStorage.getItem('TrailerSizeId')) && this.data.trailerTypeId.id === parseInt(localStorage.getItem('TrailerTypeId'))) {
      this.bookNow = true;
    }
    this._trailerType = localStorage.getItem('TrailerType');
    this._trailerSize = localStorage.getItem('TrailerSize');
    this._driverName = localStorage.getItem('loggedInUsername');
    this._showData = this.data.autoHire;
    this._showData2 = this.data.bidDetail.status[0].message;
    this._referenceNumbers = this.data.referenceNumber.split(',')
  }


  openOfferAccept(): void {
    const dialogRef = this.dialog.open(AssignTruckComponent, {
      width: '560px',
      data: this.data
    }).afterClosed().subscribe(res => {
      if (res) {
        this._offerAccepted.emit(true)
      }
    })

    this.close();

  }


  openOfferDecline(): void {
    const dialogRef = this.dialog.open(DeclineOfferComponent, {
      width: '560px',
      data: { lynk: this.data.lynk, offerId: this.data.offerId },
    }).afterClosed().subscribe(res => {
      if (res) {
        this._offerDeclined.emit(true)
        this.close();
      }
    })
    this.close();
  }
  //#region Helper methods
  openOfferConfirmation(argLoadData: any): void {
    const dialogRef = this.dialog.open(OfferConfirmationComponent, {
      width: '692px',
      data: argLoadData
    });
    this.close();
  }
  // onAssignTruck(){
  //   const dialogRef = this.dialog.open(AssignTruckComponent, {
  //     width: '650px',
  //     data: this.data
  //   })
  //   this.close();
  // }

  onSubmit() {
    if (this.data.autoHire) {
      if(localStorage){
      this._commonService.showPageLoader('JobDetailComponent-onSubmit');
    const getHiredModel = new GetHiredDetail();
    getHiredModel.loadId = parseInt(this.data.loadId || this.data.id);
    getHiredModel.vehicleId = parseInt(localStorage.getItem('TruckId'));
    getHiredModel.trailerId = parseInt(localStorage.getItem('TrailerId'));
    getHiredModel.driverId = parseInt(localStorage.getItem('id'));
    this._loadService.getHired(getHiredModel).subscribe(res => {
      if (res.data) {
        this.openOfferConfirmation(this.data);
        this._commonService.hidePageLoader('JobDetailComponent-onSubmit');
      }
      else {
        this._commonService.showSnakeBar(res.message);
        this._commonService.hidePageLoader('JobDetailComponent-onSubmit');
      }
    });
  }
      else {
      this._commonService.showSnakeBar("Please fill all the mandatory fields!");
      this._commonService.hidePageLoader('JobDetailComponent-onSubmit');
    }
  }
    else {
      this._isSubmitted = true;
      if (localStorage) {
        this._commonService.showPageLoader('JobDetailComponent-onSubmit');
        const offerAcceptDecline = new OfferAcceptDecline();
        offerAcceptDecline.offerId = parseInt(this.data.offerId);
        offerAcceptDecline.vehicleId = parseInt(localStorage.getItem('TrailerTypeId'));
        offerAcceptDecline.trailerId = parseInt(localStorage.getItem('TrailerTypeId'));
        offerAcceptDecline.driverId = parseInt(localStorage.getItem('id'));
        offerAcceptDecline.acceptOrDecline = parseInt(eOfferAcceptDecline.Accept);
        this._offerAcceptService.offerAdd(offerAcceptDecline).subscribe(res => {
          if (res.data) {
            // this._offerAccepted = true;
            this.openOfferConfirmation(this.data);
            this.close();
            this._commonService.hidePageLoader('JobDetailComponent-onSubmit');
          }
          else {
            this._commonService.showSnakeBar(res.message);
            this._commonService.hidePageLoader('JobDetailComponent-onSubmit');
          }
        });
      }
      else {
        this._commonService.showSnakeBar('Pleae fill all the mandatory fields!');
        this._commonService.hidePageLoader('JobDetailComponent-onSubmit');
      }
    }
  }



  close(): void {
    this.dialogRef.close();
  }
}

