<div class="right_sec">
    <div class="main_inner chat-box-page">
        <div class="chat-area">
            <div class="chat-main-area">
                <div class="chat-header">
                    <div class="back-area">
                        <span class="material-icons" (click)="closeChatDialog()">
                                        keyboard_arrow_left
                                    </span>
                    </div>
                    <div class="d-flex user-pic-name align-items-center">
                        <div class="user-images">
                            <img *ngIf="data.userInfo.profileUrl!==' ' " src={{data.userInfo.profileUrl}} onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" />
                            <img *ngIf="data.userInfo.profileUrl===' ' " src="assets/images/person-ic.jpg" onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" />
                        </div>
                        <div><strong>{{data.userInfo.company}}</strong>
                            <p class="text-des1cription-888 mb-0">{{data.userInfo.address}}</p>
                        </div>
                    </div>
                </div>
        
                <div class="chat-inner-box" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                    <div class="user-chat" *ngFor='let item of pubnub.getMessage(channel); index as i;'>
                        <div class="chat-date" *ngIf="i === 0 || item.message.date !== pubnub.getMessage(channel)[i].message.date">
                            <span>{{item.message.dateTime | date:'fullDate'}}</span>
                        </div>
                        <div class="d-flex user-pic-name">
                            <div class="user-images"><img src={{item.message.sender.profileUrl}} alt=""></div>
                            <div><strong class="name">{{item.message.sender.company}}
                                <strong *ngIf="item.message.sender.driver">	• {{item.message.sender.driver}}</strong>
                            </strong>
                                <p>
                                    {{item.message.message}}
                                </p>
                            </div>
                        </div>
                        <div class="chat-time">{{item.message.dateTime | date:'shortTime'}}</div>
                    </div>
        
                </div>
        
                <div class="chat-footer">
        
                    <div class="text-with-action-ic">
                        <mat-form-field appearance="outline">
                            <mat-label>Type a new message</mat-label>
                            <input #box matInput (keyup.enter)="sendMessage(box.value); box.value=''" autocomplete="off" (keyup)="getLength(box.value)">
                        </mat-form-field>
                    </div>
        
        
                    <button
                    [className]="applyClass ? 'btn rounded_btn large_btn green_btn' : 'btn rounded_btn large_btn cancel_btn'"
                                 (click)="sendMessage(box.value); box.value=''">
                                    Send
                                </button>
        
                </div>
        
            </div>
            <div class="chat-contract-info" *ngIf="showContractDetail">
                <h3 class="heading3">Contract information</h3>
                <div class="chat-contract-listing">
                    <div class="chat-contract-listing-inner">
                        <label>Active Contracted :</label>
                        <span>{{data.activeLoads}}</span>
                    </div>
                    <div class="chat-contract-listing-inner">
                        <label>Completed Contracted :</label>
                        <span>{{data.completedLoads}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>