import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }


  getValueByKey(key: string): string {
    return window.localStorage[key];
  }

  saveValueByKey(key: string, value: any): void {
    window.localStorage[key] = value;
  }

  deleteValueByKey(key: string) {
    window.localStorage.removeItem(key);
  }
}
