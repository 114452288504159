import { Injectable } from '@angular/core';
import { CommonHttpService } from '@app/core/services/common-http.service';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  //#region members
  headers: HttpHeaders;

  //#endregion

  /**
   * Container Path
   */
  private basePath = 'master/'

  constructor(
    private commonHttpService: CommonHttpService,
  ) { }


  /**
   * Get Hazmat Classes
   */
  public getHazmatClasses(): Observable<any> {
    return this.commonHttpService.get<any>(`${this.basePath}hazmat-classes`)
  }


  /**
   *  Get Hazmat Groups 
   */
  public getHazmatGroups(): Observable<any> {
    return this.commonHttpService.get<any>(`${this.basePath}hazmat-groups`)
  }


  /**
   * Get Trailer Types
   */
  public getTrailerTypes(): Observable<any> {
    return this.commonHttpService.get<any>(`${this.basePath}trailer-types`)
  }


  /**
   *  get Trailer Sizes
   */
  public getTrailerSizes(): Observable<any> {
    return this.commonHttpService.get<any>(`${this.basePath}trailer-sizes`)
  }

  /**
   * Miscellaneous options
   * 
   */
  public getMiscellaneousOptions(): Observable<any> {
    return this.commonHttpService.get<any>(`${this.basePath}miscellaneous-options`)
  }

  /**
   * Image Upload 
   */
  public uploadImage(argImage: FormData): Observable<any> {
   
     return this.commonHttpService.post<any,any>(`${this.basePath}upload-file`, argImage)
  }

  /**
   * Get Subscription Plans 
   */
  public getSubscriptionPlans(): Observable<any> {
    return this.commonHttpService.get<any>(`${this.basePath}plans`)
  }

  private formatErrors(error: any) {
    console.log('api call error:: ', error);
    return Observable.throw(error.json());
  }

}

