import { Injectable } from '@angular/core';
import { CommonHttpService } from '@app/core/services/common-http.service';
import { NotificationTokenModel } from '../models/response-model';
import { Observable } from 'rxjs';
import { ReadNotification } from '@app/modules/shipper/models/resmodel/product.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
 
  route = 'user/fs-token-registration'
  notificationRoute = 'user/notification'
  deleteNotification = 'user/delete-notification'
  constructor(
    private commonHttpService: CommonHttpService,
  ) { }

  public sendToken(argToken:NotificationTokenModel): Observable<any>{
    return this.commonHttpService.post(`${this.route}`, argToken);
  }
  
  public getNotification(): Observable<any> {
    return this.commonHttpService.get<any>(`${this.notificationRoute}`)

  }

  public readNotification(argId:number,read:ReadNotification): Observable<any>{
    return this.commonHttpService.put<ReadNotification,ReadNotification>(`${this.notificationRoute}/${argId}`,read)

  }

  public clearNotification(): Observable<any>{
    return this.commonHttpService.delete(`${this.deleteNotification}`);
  }
  
}
