import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-resolve-dispute',
  templateUrl: './confirm-resolve-dispute.component.html',
  styleUrls: ['./confirm-resolve-dispute.component.scss']
})
export class ConfirmResolveDisputeComponent implements OnInit {

  onDataRecieved = new EventEmitter();
  _totalstar = 5;

  constructor(
    public dialogRef: MatDialogRef<ConfirmResolveDisputeComponent>,
    private _domSanitizer: DomSanitizer,
    private _router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data.description = this._domSanitizer.bypassSecurityTrustHtml(this.data.description);
  }

  ngOnInit(){
    
  }

 

  closeDialog():void{
    this._router.navigate(['carrier/shipments/dispute']);
    this.dialogRef.close();
  }


}
