<div mat-dialog-content class ="mat-dialog-content">
        <div class="text-center mb-0 mt-3 position-relative">
            <span class="material-icons position-absolute close-icon-modal" (click)="closeDialog()">close</span>
            <img src="assets/images/modal-check.svg" alt="" class="mb-3">
            <!-- <img src="assets/images/icon-decline.svg" alt="" class="mb-3"> -->
            <h2 class="mb-2 fw-400 f-22">Offer Accepted!</h2>
            <p class="f-16 pb-0">LYNK ID# <span class="green_text">{{data.lynk}}</span>, job notification sent to the shipper.</p>
        </div>
    </div>
    <div mat-dialog-actions class="mat-dialog-actions justify-content-center">
        <button mat-button class="btn rounded_btn green_btn mr-2 small_btn px-4 fw-400" (click)="closeDialog()"> OK</button>
    </div>
