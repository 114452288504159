<div class="main_inner empty-wrapper">
        <div class="cs_row">
          <div class="col col-12">
            <div class="no-data-found clearfix">
              <div class="card_blocks_sec no-border">
                <div class="no-data-come">
                  <img src="assets/images/no-data.svg" alt="" />
                  <h2 class="heading2 mt-4 mb-2">Oops! there is no {{pageTitle}} found</h2>
                  <p class="fw-400">We are not able to find the data inside the {{pageName}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      