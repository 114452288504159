<section *ngIf="_userType && _accessToken" class="login text-center">
    <div>
        <div class="container-inner mb-6">
            <div class="px-3 py-3 w-100">
                <div class="col d-flex justify-content-between align-items-center login-head">
                    <img src="assets/images/logo.svg" alt="">
                    <div>Is there any trouble? <a href="">Contact support</a></div>
                </div>
            </div>
        </div>
        <div class="login-content-wrapper mx-auto login-content-wrapper-pricing">

            <div class="heading-with-back-arrow upgrade-section">


                <!-- <div class="back-annual-plan mt-0 mb-4">
                        <span (click)="onRedirectToRegistration()" routerLinkActive="router-link-active" class="material-icons">
                            keyboard_backspace
                        </span>
                </div> -->

                <div class="d-flex align-items-center mb-0 w-100">
                    <div class="w-100 d-flex back-annual-heading">
                        <div class="back-annual-plan upgrade-icon">
                            <span (click)="onRedirectToUsertype()" class="material-icons">
                                keyboard_backspace
                            </span>
                        </div>
                        <p class="heading text-center w-100 mb-4">Select subscription plan for
                            <ng-container *ngIf="_userType == eUserType.SHIPPER"> Shipper</ng-container>
                            <ng-container *ngIf="_userType == eUserType.CARRIER"> Carrier</ng-container>
                            <ng-container *ngIf="_userType == eUserType.SOLODRIVER"> Solo Driver</ng-container>
                        </p>

<!--                         <ng-container *ngIf="_userType == eUserType.Carrier">
                            <mat-radio-group class="radio-pricing" aria-label="Select an option">
                                <mat-radio-button value="3" class="mr-4">Company</mat-radio-button>
                                <mat-radio-button value="4">Solo Driver</mat-radio-button>
                            </mat-radio-group>
                        </ng-container> -->

                    </div>
                </div>

            </div>

        </div>

        <div class="login-content box_shodow plan-content">
            <!-- Subscription Package -->
            <div class="select_plan_page">
                <div class="select_plan_list">
                    <div class="plan_box selected_plan position-relative" *ngFor="let iPlan of _subscriptionPlans">
                        <div class="corner-ribbon top-right sticky orange" *ngIf="iPlan.id == current">Current</div>
                        <h3 class="heading3">{{iPlan.interval == 'year' ? 'Annual Plan': 'Monthly Plan'}}</h3>
                        <div><span class="value"><sup>{{iPlan.currency}}</sup>{{iPlan.amount}}
                                <sub>/{{iPlan.interval}}</sub></span></div>
                        <button *ngIf="iPlan.id == current" mat-button class="btn large_btn rounded_btn green_btn disable-button">Subscribe Now</button>
                         <button mat-button *ngIf="iPlan.id != current" class="btn large_btn rounded_btn green_btn"
                         (click)="getPlanDetails(iPlan.id)">Subscribe Now</button>
                    </div>
                </div>
            </div>
            <!-- //Subscription Package -->
        </div>

    </div>
    <!-- <p class="sign-up text-center">
        Don't have an account?<a routerLink="/account/login" color="green2" class="text_underline fw-400 ml-2">Log
            in</a>
    </p> -->

</section>
<!-- <ng-container *ngIf="_routeToCardDetails">
    <app-update-subscription-payment [_accessToken]="_accessToken" [_planId]="_selectedPlanId"></app-update-subscription-payment>
</ng-container> -->