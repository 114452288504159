import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ShipmentLoadDetail } from '@app/modules/carrier/models/carrier.model';
import { StarRatingComponent } from 'ng-starrating';

@Component({
  selector: 'app-comman-profile',
  templateUrl: './comman-profile.component.html',
  styleUrls: ['./comman-profile.component.scss']
})
export class CommanProfileComponent implements OnInit {
  //#region members
  userSuperAdmin:boolean = false;
  @Input() data: ShipmentLoadDetail.Shipperinfo = {} as ShipmentLoadDetail.Shipperinfo;
  @ViewChild('ratingChange') ratingChange: StarRatingComponent;
  _readonly: boolean;
  totalstar = 5;
  //#endregion

  //#region Life cycle hooks 
  constructor() {}

  ngOnInit(): void {

  }
  

  //#endregion

}

