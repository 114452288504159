
import { Injectable } from '@angular/core';
import { CommonHttpService } from '@app/core/services/common-http.service';
import { DriverModel } from '../models/driver.model';
import { Observable } from 'rxjs';
import { OfferAcceptDecline } from '../models/offer-accept-model';

@Injectable({
  providedIn: 'root'
})
export class OfferAcceptService {
  private route = 'load/invitations/accept-or-decline'
  private offerRoute = 'solodriver/load/offer/acceptdecline'
  constructor( private commonHttpService: CommonHttpService) { }


  public offerAdd(argOfferAcceptModel:OfferAcceptDecline):Observable<any>{
    return this.commonHttpService.post<OfferAcceptDecline, OfferAcceptDecline>(`${this.offerRoute}`, argOfferAcceptModel)
  }

  public add(argOfferAcceptModel:OfferAcceptDecline):Observable<any>{
    return this.commonHttpService.post<OfferAcceptDecline, OfferAcceptDecline>(`${this.route}`, argOfferAcceptModel)
  }

  
}
