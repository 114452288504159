<div mat-dialog-content>
    <div class="text-center mb-0 mt-4 position-relative">
        <span class="material-icons position-absolute close-icon-modal" (click)="close()">close</span>
        <!-- <img src="assets/images/modal-check.svg" alt="" class="mb-3"> -->
        <img src="assets/images/icon-decline.svg" alt="" class="mb-3">
        <h2 class="mb-2 fw-400 f-22">Offer Declined!</h2>
        <p class="f-16 pb-0">LYNK ID# <span class="green_text">{{data}}</span>, job notification sent to the shipper.</p>
    </div>
</div>
<div mat-dialog-actions class="justify-content-center d-flex mb-4 mt-3">
    <button mat-button class="btn rounded_btn green_btn small_btn px-4 fw-400" (click)="close()"> OK</button>
</div>