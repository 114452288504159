<div class="chat-area">
    <div class="chat-main-area">
        <div class="chat-header">
            <div class="back-area">
                <span class="material-icons" (click)="closeChatBox()">
                                keyboard_arrow_left
                            </span>
            </div>
            <div class="d-flex user-pic-name align-items-center">
                <div class="user-images"><img src={{data.userInfo.profileUrl}} onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" alt=""></div>
                <div><strong>{{data.userInfo.company}}</strong>
                    <p class="text-des1cription-888 mb-0">{{data.userInfo.address}}</p>
                </div>
            </div>
            <!-- <div class="form_row">
                            <div class="input_block">
                                <div class="input_hover input-border input-border-with-icon">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <div class="input-icon-front"><span class="material-icons green-text">search</span></div>
                                        <input class="input_fld" matInput placeholder="Search this conversation">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div> -->
        </div>

        <div class="chat-inner-box" #scrollMe [scrollTop]="scrollMe">
            <div class="user-chat" *ngFor='let item of pubnub.getMessage(channel); index as i;'>
                <div class="chat-date" *ngIf="i === 0 || item.message?.date !== pubnub.getMessage(channel)[i].message.date">
                    <span>{{item.message?.dateTime | date:'fullDate'}}</span>
                </div>
                <div class="d-flex user-pic-name">
                    <div class="user-images"><img src={{item.message?.sender?.profileUrl}} onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" alt=""></div>
                    <div><strong class="name">{{item.message?.sender?.company}}
                        <strong *ngIf="item.message.sender.driver">	• {{item.message.sender.driver}}</strong>
                    </strong>
                        <p>
                            {{item.message?.message}}
                        </p>
                    </div>
                </div>
                <div class="chat-time">{{item.message?.dateTime | date:'shortTime'}}</div>
            </div>

            <!-- <div class="user-chat" *ngFor='let item of messages'>
                <div class="chat-date">
                    <span>{{item.date | date:'fullDate'}}</span>
                </div>
                <div class="user-chat" *ngFor='let msg of item.messages'>
                    <div class="d-flex user-pic-name">
                        <div class="user-images"><img src={{msg.sender.profileUrl}} alt=""></div>
                        <div><strong class="name">{{msg.sender.company}}</strong>
                            <p>
                                {{msg.message}}
                            </p>
                        </div>
                    </div>
                    <div class="chat-time">{{msg.date | date:'shortTime'}}</div>
                </div>
            </div> -->

        </div>

        <div class="chat-footer">
            <!-- <div class="attch-ic">
                            <span class="material-icons">
                                attach_file
                            </span>
                        </div> -->

            <div class="text-with-action-ic">
                <mat-form-field appearance="outline">
                    <mat-label>Type a new message</mat-label>
                    <input #box matInput (keyup.enter)="sendMessage(box.value); box.value=''" autocomplete="off" (keyup)="getLength(box.value)">
                </mat-form-field>
                <!-- <div class="text-right-icon">
                                <span class="material-icons">
                                    settings
                                </span>
                                <span class="material-icons">
                                    insert_emoticon
                                </span>
                            </div> -->
            </div>


            <button
            [className]="applyClass ? 'btn rounded_btn large_btn green_btn' : 'btn rounded_btn large_btn cancel_btn'"
                         (click)="sendMessage(box.value); box.value=''">
                            Send
                        </button>

        </div>

    </div>
    <!-- <div class="chat-contract-info">
                    <h3 class="heading3">Contract information</h3>
                    <div class="chat-contract-listing">
                        <div class="chat-contract-listing-inner">
                            <label>Active Contracted :</label>
                            <span>01</span>
                        </div>
                        <div class="chat-contract-listing-inner">
                            <label>Completed Contracted :</label>
                            <span>03</span>
                        </div>

                    </div>
                </div> -->
</div>