<ng-container *ngIf="_loaderRequestQueue.length">
    <div class="d-flex">
        <div class="spinner full-width-loader">
            <svg class="spinner-loader-svg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="transparent" stroke-width="5" cx="33" cy="33" r="30" stroke="url(#gradient)">
                </circle>
                <linearGradient id="gradient">
                    <stop offset="50%" stop-color="#157ef8" stop-opacity="1"></stop>
                    <stop offset="65%" stop-color="#157ef8" stop-opacity="0.5"></stop>
                    <stop offset="100%" stop-color="#157ef8" stop-opacity="0"></stop>
                </linearGradient>
            </svg>
            <span>Loading ...</span>
        </div>
    </div>

</ng-container>