  /**
 * Base routing module of the application.
 */

import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/route-guard/auth.guard';
import { ShipperGuard } from './core/Auth-guards/shipper.guard';
import { CarrierGuard } from './core/Auth-guards/carrier.guard';
import { DriverGuard } from './core/Auth-guards/driver.guard';
import { FinancialAdminGuard } from './core/Auth-guards/financial-admin.guard';
import { SoloDriverGuard } from './core/Auth-guards/solo-driver.guard';
import { SuperAdminGuard } from './core/Auth-guards/super-admin.guard';

// Define the routes

const routes: Routes = [
  {
    path: '', pathMatch: 'full', redirectTo: '/account/login'

  },
  {
    path: 'pages', canActivate : [AuthGuard],
    loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'uikit', canActivate : [AuthGuard],
    loadChildren: () => import('./modules/uikit/uikit.module').then(m => m.UikitModule)
  },
  {
    path: 'users', canActivate : [AuthGuard],
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'shipper', canActivate : [AuthGuard, ShipperGuard],
    loadChildren: () => import('./modules/shipper/shipper.module').then(m => m.ShipperModule)
  },
  {
    path: 'carrier', canActivate : [AuthGuard, CarrierGuard],
    loadChildren: () => import('./modules/carrier/carrier.module').then(m => m.CarrierModule)
  },
  {
    path: 'driver', canActivate : [AuthGuard, DriverGuard],
    loadChildren: () => import('./modules/driver/driver.module').then(m => m.DriverModule)
  },
  {
    path: 'solo-driver', canActivate : [AuthGuard, SoloDriverGuard],
    loadChildren: () => import('./modules/solo-driver/solo-driver.module').then(m => m.SoloDriverModule)
  },
  {
    path: 'finance', canActivate : [AuthGuard, FinancialAdminGuard],
    loadChildren: () => import('./modules/finance-admin/finance-admin.module').then(m => m.FinanceAdmin)
  },
  {
    path: 'carrier-finance', canActivate : [AuthGuard, FinancialAdminGuard],
    loadChildren: () => import('./modules/carrier-finance-admin/carrier-finance-admin.module').then(m => m.CarrierFinanceAdminModule)
  },
  {
    path: 'dispatcher', canActivate : [AuthGuard, FinancialAdminGuard],
    loadChildren: () => import('./modules/carrier-dispatcher/carrier-dispatcher.module').then(m => m.CarrierDispatcherModule)
  },
  {
    path: 'super-admin', canActivate : [AuthGuard, SuperAdminGuard],
    loadChildren: () => import('./modules/super-admin/super-admin.module').then(m => m.SuperAdminModule)
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes,{ scrollPositionRestoration: 'top' }),
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
