<div class="profile-box-area">
    <div class="profile-box-img">
        <img *ngIf="shipperInfo.logoUrl!==' '" src={{shipperInfo.logoUrl}}
            onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" />
    </div>
    <div class="profile-box-info">
        <h3>{{shipperInfo.companyName}}</h3>
        <div *ngIf = "userSuperAdmin == false" [ngClass]="{'active': shipperInfo?.isFavorite}" class="like-area">
            <span class="material-icons" (click)="onMarkFavourite(shipperInfo)">
                favorite_border
            </span>
        </div>
        <div>
            <star-rating #ratingChange value="{{shipperInfo?.rating}}" totalstars={{totalstar}} checkedcolor="#51af57"
                uncheckedcolor="#ddd" size="24px" readonly='true'></star-rating>
        </div>
        <div class="reviews-number">{{shipperInfo.rating}} Ratings & {{shipperInfo.reviews}} Reviews</div>
        <!-- <p>Member Since: {{shipperInfo.memberSince}}</p> -->
    </div>
    <div class="row">
        <div class="col-sm-12">
            <hr>
        </div>
        <div *ngIf = "userSuperAdmin == false" class="col-sm-12 d-flex align-items-center justify-content-between">
            <label class="f-14 fw-500 mb-0">Total job posted: </label>
            <span class="badge-label bg-green">{{shipperInfo.totalJobs}}</span>
            <!-- <span class="badge-label bg-green"></span> -->
        </div>
    </div>
</div>