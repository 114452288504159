export enum eUserType{
    ADMIN = 1,
    SHIPPER,
    CARRIER,
    DRIVER,
    SOLODRIVER,
    BRANCH_ADMIN,
    FINANCIAL_ADMIN,
    STAFF,
    CARRIER_FINANCIAL_ADMIN,
    CARRIER_BRANCH_ADMIN,
    DISPATCHERS
  }
export const MaskTypes = {
    phoneMask: [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    zipcodeMask: [/[0-9]/, /\d/, /\d/, /\d/, /\d/],
    phonecodeMask: [/[1-9]/, /\d/, /\d/, /\d/],
    otpMask: [/[1-9]/, '-', /\d/, '-', /\d/, '-', /\d/, '-', /\d/],
    meridiemTime: [/\d/, /\d/, ':', /\d/, /\d/],
};


