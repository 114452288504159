import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-assigned-staff',
  templateUrl: './assigned-staff.component.html',
  styleUrls: ['./assigned-staff.component.scss']
})
export class AssignedStaffComponent implements OnInit {

  @Input() staffInfo: any;
  constructor() { }

  ngOnInit(): void {}

}
