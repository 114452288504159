/**
 * Interceptor
 * This is HttpTokenInterceptor that adds a token to each api request.
 */


import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonBase } from "@core/interfaces/common-base";
import { JwtService } from '../services/jwt.service';

/**
 * Creates a HttpTokenInterceptor.
 * @class HttpTokenInterceptor
 * @classdesc This class is used to add user token/auth entity to the headers.
 * You can define the custom header here.
 */

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig: CommonBase = {};
    const token = this.jwtService.getToken();
    if (token) {
      headersConfig.Authorization = `Bearer ${this.jwtService.getToken()}`
    }
    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}

 