import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  OnDestroy,
} from "@angular/core";
import { PubNubAngular } from "pubnub-angular2";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-pubnub-chat",
  templateUrl: "./pubnub-chat.component.html",
  styleUrls: ["./pubnub-chat.component.scss"],
})
export class PubnubChatComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  pubnub: PubNubAngular;
  messages: any[];
  @ViewChild("scrollMe") private myScrollContainer: ElementRef;
  scrolltop: number = null;

  public channel: string;
  private sender: any;
  applyClass = false;
  listener: any;
  messageId:any
  ChannleData:any
  senderName:any
  constructor(
    pubnub: PubNubAngular,
    public dialogRef: MatDialogRef<PubnubChatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.channel = `${data.channel}x`;
    this.sender = data.senderInfo;
  }

  ngOnInit() {
    this.pubnub = new PubNubAngular();
    this.pubnub.init({
      publishKey: "pub-c-dde615cd-e8b7-4dd1-a8b9-266d6bbd9a5b",
      subscribeKey: "sub-c-671f361c-d985-418a-a617-c9d00261da35",
      //orignal keys
      // publishKey: "pub-c-f4dd5c59-71f8-49a7-862b-42a6005ad475",
      // subscribeKey: "sub-c-5b2e73fe-ec71-11ea-8cd7-2246479f46d6",
    });
    this.pubnub.subscribe({
      channels: [this.channel],
      triggerEvents: ["message"],
      autoload: 10,
    });
    this.fetchMessage() 
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  ngOnDestroy() {
    this.pubnub.removeListener(this.listener);
    this.pubnub.unsubscribe(this.channel);
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  fetchMessage(){   
    this.pubnub.fetchMessages({
      channels: [this.channel],
      count: 1
    },((a,b)=>{
      this.ChannleData =  b.channels[this.channel]
      this.ChannleData.map((messageId)=>{
       this.messageId =  messageId.timetoken
       this.senderName =  messageId.message.sender.company
      })
      if(this.senderName != this.sender.company){
        this.OnMessage()
      }
    }))
  }

  getMessages() {   
    this.messages = [];
    const pubnubMsg = this.pubnub.getMessage(this.channel);
    // tslint:disable-next-line: prefer-const
    let idx = 0;
    // tslint:disable-next-line: prefer-const
    let oldDate;
    pubnubMsg.forEach((m) => {
      if (m.message.date === oldDate) {
        this.messages[idx].messages.push(m.message);
      } else {
        idx = idx === 0 ? 0 : idx++;
        oldDate = m.message.date;
        const msg = [];
        msg.push(m.message);
        this.messages.push({ date: m.message.dateTime, messages: msg });
      }
    });
  }

  sendMessage(message: string) {
    if (message.trim() !== "") {
      this.pubnub.publish({
        // tslint:disable-next-line: max-line-length
        channel: this.channel,
        ttl: 0,
        message: {
          sender: this.sender,
          message,
          date: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
          dateTime: Date.now().toString(),
        },
      });
      // await this.getMessages();
    }
  }
  OnMessage(){
    this.pubnub.publish({
      channel: `${this.channel}x`,
      message: {
          readMessages: this.messageId,
      }
    })
  }
  getLength(message: string) {
    this.applyClass = message.length > 0;
  }
  closeChatBox() {
    this.dialogRef.close(true); 
    this.fetchMessage(); 
  }
}
