/**
 * @module AppModule
 * @description
 * Base module of the application
 */

// import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import CoreModule from '@core/core.module';
import { AppThemeModule } from '@app/modules/theme/app-theme.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { NgxSpinnerModule } from "ngx-spinner";  
import { SharedModule } from './shared/shared.module';
import { LocalTimeZoneConverter } from './shared/local-timezone.directive';
import { UtcTimeZoneConverter } from './shared/UTC-format.directive';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { PubNubAngular } from 'pubnub-angular2';
import { PubnubChatComponent } from './modules/pubnub-chat/pubnub-chat.component';

@NgModule({
  declarations: [
    AppComponent,
    PubnubChatComponent 
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    CoreModule,
    AppThemeModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    SharedModule,
    NgScrollbarModule, 
  ],
  providers: [DatePipe, LocalTimeZoneConverter, UtcTimeZoneConverter, PubNubAngular],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
