import { Injectable } from "@angular/core";
// export class LocalTimeZoneConverter{
//   public date(str) {
//     var date = new Date(str);
//     return date.toString();
//   }
// }

@Injectable()
export class LocalTimeZoneConverter{

  public date(time, fullDateTime) {
    
    var day = new Date(fullDateTime).getDate();
    var month = new Date(fullDateTime).getMonth();
    var year = new Date(fullDateTime).getFullYear();
    var date = new Date(`${day}/${month}/${year} ${time}`);
    return date;
  }
}
