import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { ShipmentLoadDetail } from '@app/modules/carrier/models/carrier.model';
import { LoadService } from '@app/modules/shipper/services/load.service';
import { MarkFavouriteCarrierModel } from '../carrier-profile/carrier-profile.component';

@Component({
  selector: 'app-shipper-profile',
  templateUrl: './shipper-profile.component.html',
  styleUrls: ['./shipper-profile.component.scss']
})
export class ShipperProfileComponent implements OnInit {
  //#region members
  userSuperAdmin:boolean = false;
  @Input() shipperInfo: ShipmentLoadDetail.Shipperinfo = {} as ShipmentLoadDetail.Shipperinfo;
  totalstar = 5;
  //#endregion

  //#region Life cycle hooks
  constructor(
    private _loadService: LoadService,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    if(this.shipperInfo.superAdmin){
      this.userSuperAdmin = true
    }
  }

  //#endregion

  //#region events
  onMarkFavourite(argShipper) {
    const markFavModel = new MarkFavouriteCarrierModel();
    markFavModel.carrierId = argShipper.id;
    this._loadService.markFavourite(markFavModel).subscribe(res => {
      if (res.statusCode == 200 || res.statusCode == 201) {
        // do something
        if (argShipper.isFavorite) {
          argShipper.isFavorite = false;
        } else {
          argShipper.isFavorite = true;
        }
      } else {
        this._commonService.showSnakeBar(res.message);
      }
    });
  }
  //#endregion



}
